import {
  IconButton,
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from '@material-ui/core'
import { Clear, GetApp } from '@material-ui/icons'
import React from 'react'

interface FileUploadProps extends StandardTextFieldProps {
  onHandleUploadChange?: any
  multiple?: any
  previewFiles?: any
  files?: any
  imagePreview: any
  accept?: string
  mapData?: string
  disabled?: boolean
  singleFile?: boolean
  singleFileClear?: any
}

export const FileUploadInput = ({
  onHandleUploadChange,
  multiple,
  previewFiles,
  files,
  imagePreview,
  accept,
  mapData,
  disabled,
  singleFile,
  singleFileClear,
  ...InputProps
}: FileUploadProps) => {
  const onClickHiddenInput = (mapData?: any) => {
    if (mapData) {
      document.getElementById('icon-button-file-' + mapData).click()
    } else {
      document.getElementById('icon-button-file').click()
    }
  }
  const handleDisplayFileInput = (files, mapData?) => {
    let names = ''
    if (files?.length > 1) {
      for (let i = 0; i < files?.length; i++) {
        if (i === files?.length - 1) {
          names += files[i]?.name
          break
        }
        names += files[i]?.name + ', '
      }
    }

    // typeof files === 'object'
    return files?.length === undefined
      ? files?.name
      : files?.length === 1
      ? files[0]?.name
      : files?.length > 1
      ? names
      : //? `${files.length} files`
        ''
    // return files?.length === 0 || files === undefined
    //   ? ''
    //   : files?.length === 1
    //   ? // files[0]?.name ??
    //     files?.name
    //   : files?.length > 1
    //   ? `${files.length} files`
    //   : null
  }

  return (
    <>
      <TextField
        {...InputProps}
        disabled={disabled}
        fullWidth
        value={handleDisplayFileInput(files, mapData)}
        onClick={() => {
          if (!disabled && !singleFile) {
            onClickHiddenInput(mapData)
          } else if (!disabled && files?.length === 0 && singleFile) {
            onClickHiddenInput(mapData)
          } else if (!disabled && files?.length > 0 && singleFile) {
            singleFileClear([])
          }
        }}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <>
              <InputAdornment
                position="end"
                onClick={() => {
                  if (files?.length > 0 && singleFile) {
                    singleFileClear([])
                  }
                }}
              >
                <IconButton className="transform-180" aria-label="">
                  {singleFile && files?.length > 0 ? <Clear /> : <GetApp />}
                </IconButton>
              </InputAdornment>
              <input
                accept={accept} // sorry chia if uncomment this cause error :(
                hidden
                multiple={multiple}
                // multiple
                onChange={e => {
                  if (!singleFile) {
                    onHandleUploadChange(e)
                    e.target.value = null
                  } else if (files?.length === 0 && singleFile) {
                    onHandleUploadChange(e)
                    e.target.value = null
                  }
                }}
                id={
                  mapData ? `icon-button-file-${mapData}` : 'icon-button-file'
                }
                type="file"
              />
            </>
          ),
        }}
      />
      <div className="preview-wrapper">{imagePreview}</div>
    </>
  )
}
