import React, { lazy } from 'react';

const ProjectCostAdjustmentListing = lazy(() =>
  import('./ProjectCostAdjustmentListing').then(module => ({
    default: module.ProjectCostAdjustmentListing,
  })),
);

const ProjectCostAdjustmentForm = lazy(() =>
  import('./ProjectCostAdjustmentForm').then(module => ({
    default: module.ProjectCostAdjustmentForm,
  })),
);

const ProjectCostAdjustmentItemForm = lazy(() =>
  import('./components/ProjectCostAdjustmentItemForm').then(module => ({
    default: module.ProjectCostAdjustmentItemForm,
  })),
);

const ProjectCostAdjustmentDetail = lazy(() =>
  import('./ProjectCostAdjustmentDetail').then(module => ({
    default: module.ProjectCostAdjustmentDetail,
  })),
);

const projectCostAdjustmentRoutes = [
  {
    props: {
      exact: true,
      path: '/project-cost-adjustment',
    },
    component: <ProjectCostAdjustmentListing />,
  },
  {
    props: {
      exact: true,
      path: '/project-cost-adjustment/detail/:costAdjustmentID',
    },
    component: <ProjectCostAdjustmentDetail />,
  },
  {
    props: {
      exact: true,
      path: '/project-cost-adjustment/new',
    },
    component: <ProjectCostAdjustmentForm mode="new" />,
  },
  {
    props: {
      exact: true,
      path: '/project-cost-adjustment/approve-reject/:costAdjustmentID',
    },
    component: <ProjectCostAdjustmentForm mode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path: '/project-cost-adjustment/:formMode/:costAdjustmentID',
    },
    component: <ProjectCostAdjustmentForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/project-cost-adjustment/:formMode/item/add',
    },
    component: <ProjectCostAdjustmentItemForm mode="new" />,
  },
  {
    props: {
      exact: true,
      path: '/project-cost-adjustment/:formMode/item/:itemMode/:itemID',
    },
    component: <ProjectCostAdjustmentItemForm mode="new" />,
  },
  {
    props: {
      exact: true,
      path: '/project-cost-adjustment/:formMode/:costAdjustmentID/item/add',
    },
    component: <ProjectCostAdjustmentItemForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/project-cost-adjustment/:formMode/:costAdjustmentID/item/:itemMode/:itemID',
    },
    component: <ProjectCostAdjustmentItemForm mode="new" />,
  },
];

export default projectCostAdjustmentRoutes;
