import { drawDOM, exportPDF, Group } from '@progress/kendo-drawing'
import { saveAs } from 'file-saver'

const dataURItoFile = (dataurl, filename) => {
  dataurl.replace('data:application/pdf;base64,', '')
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) u8arr[n] = bstr.charCodeAt(n)

  return new File([u8arr], filename, { type: mime })

}

const formatDate = (date) => {
  return new Intl.DateTimeFormat('en-GB', {
    weekday: 'long',  // "Friday"
    day: 'numeric',   // "7"
    month: 'long',    // "February"
    year: 'numeric',  // "2025"
    hour: 'numeric',
    minute: '2-digit',
    hour12: true      // "11.25 AM"
  }).format(date).replace(',', '');
};

/// PDF onDownload Print ------------------------------------
export const onPrint = (
  width,
  fileType,
  documentNumber?: any,
  pageBreak?: boolean,
  landscape?: boolean,
  customPaperSize?: any,
  repeatHeaders?: boolean,
  pageNumbering?: boolean,
  pageNumberWithDate?: boolean,
) => {
  let element = document.getElementById('pdf-data-print')

  drawDOM(element, {
    paperSize: customPaperSize ? customPaperSize : 'A4',
    margin: { bottom: '1cm', top: '1cm', left: '1cm', right: '1cm' },
    scale: width < 769 ? 1.5 : 0.85,
    forcePageBreak: pageBreak ? '.page-break' : 'auto',
    repeatHeaders: repeatHeaders,
    landscape: landscape,
    // Must be in HTML with INLINE CSS format
    // Special for footer section ONLY because HEADER can be manipulate from <thead> with repeatHeaders: true,
    ...(pageNumbering ? {
      template: function (page) {
        return pageNumberWithDate ? `
          <div style="display: flex; flex-direction: column; height: 100%; box-sizing: border-box font-size: 10px; color: gray; padding-bottom: 5px;">
            <div style="
              display: grid;
              grid-template-columns: repeat(6, 1fr); /* 6 equal columns */
              align-items: center;
              text-align: center;
              font-size: 12px;
              color: gray;
            ">
              <div style="grid-column: span 2; text-align: left;">Date printed:  ${formatDate(new Date())}</div>
              <div style="grid-column: span 4; text-align: right;">Page ${page.pageNum} of ${page.totalPages}</div>
            </div>
          </div>`
          : `<div style="
              left: 0;
              right: 0;
              text-align: center;
              font-size: 12px;
              color: gray;
              padding: 5px 0;
              background: white;
            ">
              Page ${page.pageNum} of ${page.totalPages}
            </div>`
      }
    } : null)

  })
    .then((group: Group) => exportPDF(group, { multiPage: true }))
    .then(dataURI => {
      let fileObject = dataURItoFile(
        dataURI,
        !!documentNumber || documentNumber != null
          ?
          `${fileType} - (${documentNumber}).pdf`
          : `${fileType}.pdf`
      )
      saveAs(
        fileObject,
        !!documentNumber || documentNumber != null
          ?
          `${fileType} - (${documentNumber}).pdf`
          : `${fileType}.pdf`
      )
    })
}
