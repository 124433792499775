// import { CopyrightFooter } from '@contract-root/admin-react/src/components/Footer/CopyrightFooter';
import { ActivatedUser } from 'containers/Authentication/ActivatedUser';
import { Login } from 'containers/Authentication/Login';
import { CreatePassword } from 'containers/Authentication/Password/CreatePassword';
import { ForgotPassword } from 'containers/Authentication/Password/ForgotPassword';
import { ResetPassword } from 'containers/Authentication/Password/ResetPassword';
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import AppContext from '../Store/AppContext';
import { PrivateRoute } from './PrivateRoute';

const Routes = () => {
  const { globalState }: any = useContext(AppContext as any);
  return (
    <Switch>
      <Route path="/user/activate/:token" exact>
        <ActivatedUser />
      </Route>
      <Route path="/user/create-password/:token" exact>
        <CreatePassword />
      </Route>
      <Route path="/reset-password/:token" exact>
        <ResetPassword />
      </Route>
      <Route path="/forgot-password" exact>
        <ForgotPassword />
      </Route>
      <Route path="/login" exact>
        <Login />
      </Route>

      {globalState.routes.map((el, index) => {
        return (
          <PrivateRoute key={index} {...el?.props}>
            {/* <RouteSuspense> */}
            {el?.component}
            {/* <CopyrightFooter /> */}
            {/* </RouteSuspense> */}
          </PrivateRoute>
        );
      })}
    </Switch>
  );
};

export default Routes;
