import React from 'react'
import IFCA from '../../assets/images/ifca-white-logo.png'

export const CopyRight = () => {
  return (
    <div className="copy-right desc m-b-12">
      <div className="m-b-4">
        <img src={IFCA} alt="CopyRight" className="ifca-logo m-l-8" />
      </div>
      <div className="flex">
        <span className="flex-space m-l-8 ">
          © Copyright 2025 IFCA MSC Berhad
        </span>
        <span className="m-r-8">All Rights Reserved.</span>
      </div>
    </div>
  )
}
