import { formatDate } from '@ifca-root/react-component/src/helpers/StringNumberFunction/formatDate';
import { useMediaQuery } from 'helpers/PDFFormatter/mediaSize';
import { moneyFunction } from 'helpers/StringNumberFunction/moneyFunction';
import { amtNumStr, amtStr } from 'helpers/StringNumberFunction/numFormatter';
import React from 'react';

export const FooterAmt = (props: any) => {
  const {
    principalAmt,
    docAmt,
    taxRate,
    userData,
    bankDetails,
    glItem,
    eInvoiceData,
  } = props;
  let principalAmount = parseFloat(amtNumStr(principalAmt));
  let taxAmt = (principalAmount * taxRate) / 100;
  let documentAmt = principalAmount + taxAmt;
  const money = moneyFunction(docAmt ?? documentAmt);
  const companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
  const accountInfo = JSON.parse(localStorage.getItem('loggedInUser'));

  ////////TO DETECT CHANGE IN SCREEN SIZE///////
  const [width] = useMediaQuery();

  let fontSize;
  let paddingLeft;
  if (width < 769) {
    fontSize = 5;
    paddingLeft = '10px';
  } else {
    fontSize = 10;
    paddingLeft = '25px';
  }

  return (
    <>
      <div
        style={{
          ...style.center,
          padding: `${!!bankDetails ? '60px' : '100px'} 0px 10px 0px`,
        }}
      >
        <hr />
        <table style={{ width: '100%', fontSize }}>
          <tbody>
            <tr style={{ fontWeight: 'bold' }}>
              <td>{`Total Amount (${userData?.companyCurrencyCode ??
                companyInfo?.currencyCode ??
                accountInfo?.companyCurrencyCode}):`}</td>
              <td style={{ textAlign: 'right' }}>
                {amtStr(docAmt ?? documentAmt)}
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{ fontSize }}>
          <tbody>
            <tr>
              <td>{`${userData?.companyCurrency ??
                companyInfo?.currency ??
                accountInfo?.companyCurrency}:`}</td>
              <td style={{ width: '80%' }}>{money}</td>
            </tr>
          </tbody>
        </table>
        <hr />
        <div style={{ fontSize, fontWeight: 'bold' }}>Note:</div>
        {!!bankDetails && (
          <table style={{ fontSize, display: 'block', borderSpacing: '0px' }}>
            <tr style={{ display: 'block' }}>
              For direct bank-in, payments are to made on :
            </tr>
            <tr>
              <td>Beneficiary Name:</td>
              <td
                style={{
                  fontWeight: 'bold',
                  paddingLeft: '20px',
                }}
              >
                {companyInfo?.name ?? accountInfo?.companyName}
              </td>
            </tr>
            <tr>
              <td>Bank Account Number:</td>
              <td style={{ paddingLeft: '20px' }}>{bankDetails?.accountNo}</td>
            </tr>
            <tr>
              <td>Bank Name:</td>
              <td style={{ paddingLeft: '20px' }}>{bankDetails?.name}</td>
            </tr>
          </table>
        )}
        {eInvoiceData?.isEInvoice === false ? (
          <div style={{ fontSize }}>
            <td style={{ paddingLeft }}>
              Please notify us of any discrepancies within 14 days of receipt
              otherwise we deem that you have checked and agreed to all content
              in this document
            </td>
            <br /> This is a computer-generated document. No signature is
            required.
            <br />
            E.&.O.E
          </div>
        ) : (
          eInvoiceData?.eInvoiceValidationDate && (
            <div style={{ fontSize }}>
              Date and Time of Validation:{' '}
              {formatDate(eInvoiceData.eInvoiceValidationDate)}
              <br />
            </div>
          )
        )}
      </div>
    </>
  );
};

export default FooterAmt;

const style = {
  center: {
    fontSize: 5,
    right: 150,
    bottom: '10px',
    padding: '100px 0px 10px 0px',
    // width: '90%',
    // margin: 'auto',
  },
};
