import React, { lazy } from 'react';
import einvoiceReportingRoutes from './EInvoiceReporting/EInvoiceReportingRoutes';

const ContractConsolidateEInvoiceListing = lazy(() =>
  import('./ConsolidateEInvoiceListing/ConsolidateEInvoiceListing').then(
    module => ({
      default: module.ContractConsolidateEInvoiceListing,
    }),
  ),
);

const ContractConsolidateEInvoiceForm = lazy(() =>
  import('./ConsolidateEInvoiceForm/ConsolidateEInvoiceForm').then(module => ({
    default: module.ContractConsolidateEInvoiceForm,
  })),
);

const ContractConsolidateDetail = lazy(() =>
  import(`./ConsolidateEInvoiceDetail`).then(module => ({
    default: module.ContractConsolidateEInvoiceDetail,
  })),
);

const contractConsolidateEInvoiceRoutes = [
  {
    props: {
      exact: true,
      path: `/einvoice/consolidate-einvoice`,
    },
    component: (
      <ContractConsolidateEInvoiceListing transactionType="Consolidate E-Invoice" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/einvoice/consolidate-einvoice/add`,
    },
    component: (
      <ContractConsolidateEInvoiceForm transactionType="Consolidate E-Invoice" />
    ),
  },
  //Consolidate E-Invoice Detail Page
  {
    props: {
      exact: true,
      path: `/einvoice/consolidate-einvoice/detail/:consolidateID`,
    },
    component: (
      <ContractConsolidateDetail transactionType="Consolidate E-Invoice" />
    ),
  },
  ...einvoiceReportingRoutes,
];

export default contractConsolidateEInvoiceRoutes;
